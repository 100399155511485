.root {
    background-color: #f1f1f1;
}

.ant-steps-item {
    cursor: pointer;

    .ant-steps-item-title {
        transition: color 0.3s ease;
    }

    &:hover {
        .ant-steps-item-title {
            color: #1890ff !important;
        }
    }
}

.ant-steps-item-active {
    .ant-steps-item-title {
        font-weight: bold;
    }
}